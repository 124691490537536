import { AxiosResponse } from "axios";
import { AccountAndUserRole } from "../types";
import axiosInstance from "./axiosIntercept";

type Response = {
  merchantUser: {
    defaultAccount: any;
    id: number;
    name: string;
    email: string;
    uuid: string;
    merchantUserRoleId: number;
    active: boolean;
    emailInviteAccepted: boolean;
    emailInviteUuid: string;
    emaiInviteValidityHours: number;
    userName: string | null;
    profileImageId: string | null;
    maskedEmail: string | null;
    accountMemberships: Array<AccountAndUserRole>;
  };
  account: Array<AccountAndUserRole>;
  authenticated: boolean;
};

type Payload = {
  email: string;
  otpCode: string;
  deviceID: string;
  accountToLogIn?: string;
};

export async function verifyOtp(payload: Payload): Promise<Response> {
  const response = await axiosInstance.post<
    any,
    AxiosResponse<Response>,
    Payload
  >(`${process.env.REACT_APP_API_URL}v5/auth/business/portal/login`, payload, {
    withCredentials: true,
    headers: {
      "api-key": process.env.REACT_APP_API_KEY,
      "Content-Type": "application/json",
    },
  });

  return response.data;
}

type payload = {
  adminEmail: string;
  ownerEmail: string;
  otpCode: string;
  deviceID: string;
  accountToLogIn?: string;
};

export async function verifyOtpAdmin(payload: payload): Promise<Response> {
  const response = await axiosInstance.post<
    any,
    AxiosResponse<Response>,
    payload
  >(
    `${process.env.REACT_APP_API_URL}v1/auth/business/portal/login/admin`,
    payload,
    {
      withCredentials: true,
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
        "Content-Type": "application/json",
      },
    },
  );

  return response.data;
}
